import React from 'react';
import { graphql } from 'gatsby';
import propTypes from 'prop-types';

import SEO from '@components/seo';

import Contact from '../../components/contact';
import Hero from '../../components/hero';
import Layout from '@components/layout';
import MarkdownContent from '../../components/markdown-content/MarkdownContent';

const AllmannaVillkorLan = ({ data }) => {
  const headLine = data.contentfulPage.headLine;

  const pageContent = data.contentfulPage.pageContent[0];
  const {
    bodyText: { bodyText },
    headline,
    subtitle,
  } = pageContent;

  return (
    <Layout newStyle>
      <SEO title={headLine} />
      <Hero
        breadcrumbs={[
          { path: '/', name: 'Hem' },
          { path: '/allmanna-villkor', name: 'Allmänna villkor' },
        ]}
        title={headline}
        subtitleMarkdown={subtitle}
      />
      <div id="first">
        <MarkdownContent markdown={bodyText} />
      </div>
      <Contact isLargeContact={true} hideFAQ />
    </Layout>
  );
};

export default AllmannaVillkorLan;

AllmannaVillkorLan.propTypes = {
  data: propTypes.object,
};

export const query = graphql`
  query AllmannaVillkorLanQuery {
    contentfulPage(pageUrl: { eq: "/allmanna-villkor" }) {
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      headLine

      pageContent {
        ... on ContentfulDescriptionComponent {
          id
          headline
          subtitle
          bodyText {
            bodyText
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
